import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    currentUser: {
      date_of_birth: '0000-00-00',
    },
    token: null,
  },
  mutations: {
    SET_USER(state, payload) {
      state.currentUser = payload
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      commit('SET_USER', user)
    },
    async getUserIfNotExist({ commit, state }) {
      if (!state.currentUser.id) {
        const { data: { data } } = await Vue.prototype.$axios
          .get('user')
        commit('SET_USER', data)
      }
    },
  },
  getters: {
    getUser: state => state.currentUser,
  },
}
