import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'
import { authGuard } from '../auth/authGuard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    // Auth Routes
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/create-account',
      name: 'create-account',
      component: () => import('@/views/auth/CreateAccount.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/phone-verification',
      name: 'phone-verification',
      component: () => import('@/views/auth/PhoneVerification.vue'),
      meta: {
        layout: 'full',
        requireAuth: true,
      },
    },
    {
      path: '/otp-code',
      name: 'otp-code',
      component: () => import('@/views/auth/OtpCode.vue'),
      meta: {
        layout: 'full',
        requireAuth: true,
      },
    },
    {
      path: '/complete-profile',
      name: 'complete-profile',
      component: () => import('@/views/auth/CompleteProfile.vue'),
      meta: {
        layout: 'full',
        requireAuth: true,
      },
    },
    // End Auth Routes
    {
      path: '/dashboard',
      beforeEnter: authGuard,
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        requireAuth: true,
      },
    },
    {
      path: '/callback',
      name: 'callback',
      component: () => import('@/views/Callback.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Callback',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/landingPage/Home.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Home',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach(async (to, from, next) => {
  // const { firebaseAuth } = Vue.prototype.$auth
  // if (to.meta.requireAuth) {
  //   if (!firebaseAuth.currentUser) {
  //     next({
  //       path: '/login',
  //     })
  //   } else {
  //     try {
  //       await store.dispatch('user/getUserIfNotExist')
  //     } catch (e) {
  //       firebaseAuth.signOut()
  //       this.$store.dispatch('user/setUser', {})
  //       next({
  //         path: '/login',
  //       })
  //     }
  //     const user = store.getters['user/getUser']
  //     if (user.is_phone_number_verified) {
  //       if (to.name === 'phone-verification') {
  //         next({
  //           name: 'dashboard',
  //         })
  //       }
  //     } else if (to.name !== 'phone-verification') {
  //       next({
  //         name: 'phone-verification',
  //       })
  //     }
  //     if (user.is_profile_completed) {
  //       if (to.name === 'complete-profile') {
  //         next({
  //           name: 'dashboard',
  //         })
  //       }
  //     } else if (to.name !== 'complete-profile') {
  //       next({
  //         name: 'complete-profile',
  //       })
  //     }
  //     next()
  //   }
  // } else if (firebaseAuth.currentUser) {
  //   next({
  //     path: '/dashboard',
  //   })
  // }
  next() // make sure to always call next()!
})
export default router
